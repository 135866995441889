import { match } from 'ts-pattern'

import type { ChargingSessionWithRelations } from 'types'

export type HomeChargingSessionType =
  | 'Me' // Charger used by the employee itself
  | 'Other' // Charger used by another employee
  | 'Guest' // Charger used by the employee with a bypass-card. Employee is not involved in the payment

export function getHomeChargingSessionType(
  chargingSession: ChargingSessionWithRelations
): HomeChargingSessionType {
  return match(chargingSession.remunerationType)
    .returnType<HomeChargingSessionType>()
    .with('SPLIT_BILLING', () => 'Me')
    .with('WHITELIST', () => 'Guest')
    .with('NORMAL', () => 'Other')
    .otherwise(() => 'Other')
}
