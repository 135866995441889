import { Capacitor } from '@capacitor/core'
import { Intercom as nativeIntercom } from '@sencrop/capacitor-intercom'
import { useIntercom as useIntercomWeb } from 'react-use-intercom'

import { EventType, useTracking } from '../../analytics/hooks/useTracking'

import type { IntercomProps } from 'react-use-intercom'

export const useIntercom = () => {
  // -- Hooks --
  const webIntercom = useIntercomWeb()
  const { trackEvent } = useTracking()

  // -- Functions --
  const show = () => {
    trackEvent(EventType.Click, 'open_intercom')

    if (Capacitor.isNativePlatform()) {
      nativeIntercom.displayMessenger()
    } else {
      !webIntercom.isOpen && webIntercom.show()
    }
  }

  const boot = async (
    props: IntercomProps,
    userHashes: { web?: string; ios?: string; android?: string }
  ) => {
    if (Capacitor.isNativePlatform()) {
      const platform = Capacitor.getPlatform()

      await nativeIntercom.loginIdentifiedUser({
        userId: props.userId || '',
        email: props.email,
        userHash: platform === 'ios' ? userHashes.ios : userHashes.android,
      })

      await nativeIntercom.updateUser({
        name: props.name,
        phone: props.phone,
      })

      if (props.customAttributes) {
        await nativeIntercom.setCustomAttributes({
          attributes: props.customAttributes,
        })
      }

      // Web intercom
    } else {
      webIntercom.boot({ ...props, userHash: userHashes.web })
    }
  }

  const update = async (props: IntercomProps) => {
    if (Capacitor.isNativePlatform()) {
      await nativeIntercom.updateUser({
        name: props.name,
        phone: props.phone,
      })

      if (props.customAttributes) {
        await nativeIntercom.setCustomAttributes({
          attributes: props.customAttributes,
        })
      }
    } else {
      webIntercom.update(props)
    }
  }

  const shutdown = () => {
    if (Capacitor.isNativePlatform()) {
      nativeIntercom.logout()
    } else {
      webIntercom.shutdown()
    }
  }

  const hide = () => {
    if (Capacitor.isNativePlatform()) {
      nativeIntercom.hideLauncher()
    } else {
      webIntercom.hide()
    }
  }

  const showHelpCenter = () => {
    trackEvent(EventType.Click, 'open_intercom_help')

    if (Capacitor.isNativePlatform()) {
      nativeIntercom.displayHelpCenter()
    } else {
      webIntercom.showSpace('help')
    }
  }

  const showArticle = (articleId: number) => {
    if (Capacitor.isNativePlatform()) {
      nativeIntercom.displayArticle({
        id: articleId.toString(),
      })
    } else {
      webIntercom.showArticle(articleId)
    }

    trackEvent(EventType.Click, 'open_intercom_article')
  }

  const newMessage = (content?: string) => {
    trackEvent(EventType.Click, 'open_intercom_help')

    if (Capacitor.isNativePlatform()) {
      nativeIntercom.displayMessageComposer({
        content,
      })
    } else {
      webIntercom.showNewMessage(content)
    }
  }

  return {
    show,
    boot,
    shutdown,
    hide,
    update,
    showArticle,
    showHelpCenter,
    newMessage,
  }
}
