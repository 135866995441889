import { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { match } from 'ts-pattern'

import { Drawer, DrawerContent } from '../../../components/general/Drawer'
import { formatDecimals } from '../../../core/utils/number'
import { ColumnType, DataTable } from '../../../dataTable/components/DataTable'
import { formatPowerConsumption } from '../../../employer/utils/statistics'
import { useMember } from '../../../member/hooks/useMember'
import { useUser } from '../../../user/hooks/useUser'

import { ChargingSessionInfoHcp } from './ChargingSessionInfoHcp'
import { getHomeChargingSessionType } from './homeChargingSession'
import { ReimbursementStatusChip } from './ReimbursementStatusChip'

import type { Column } from '../../../dataTable/components/DataTable'
import type { ChargingSessionWithRelations } from 'types'

export const UsageDataTableHcp = () => {
  const { t } = useTranslation()
  const { currentMember } = useMember()
  const { user } = useUser()
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)
  const [selectedChargingSession, setSelectedChargingSession] = useState<
    ChargingSessionWithRelations | undefined
  >(undefined)

  const columns = useMemo<Column<ChargingSessionWithRelations>[]>(
    () => [
      {
        key: 'start',
        label: t('employee.chargingSessions.table.date'),
        type: ColumnType.DATE,
        sortable: true,
      },
      {
        key: 'memberId',
        label: t('employee.chargingSessions.table.name'),
        type: ColumnType.STRING,
        transformData: (data: ChargingSessionWithRelations) => {
          return match(getHomeChargingSessionType(data))
            .with('Me', () => `${user.firstName} ${user.lastName}`)
            .with('Guest', () => t('employee.chargingSessions.guest'))
            .with('Other', () => t('employee.chargingSessions.other'))
            .exhaustive()
        },
      },
      {
        key: 'hcpPrice',
        label: t('employee.chargingSessions.table.cost'),
        prepend: '€',
        type: ColumnType.NUMBER,
        decimal: 2,
        sortable: true,
        transformData: (data: ChargingSessionWithRelations) => {
          return formatDecimals(data.hcpPrice)
        },
      },
      {
        key: 'kwh',
        label: t('employee.chargingSessions.table.charged'),
        type: ColumnType.STRING,
        sortable: true,
        transformData: (data: ChargingSessionWithRelations) => {
          return formatPowerConsumption(data.kwh ?? 0)
        },
      },
      {
        key: 'status',
        label: t('employee.chargingSessions.table.status'),
        type: ColumnType.STRING,
        transformData: (session) => {
          return (
            <ReimbursementStatusChip
              chargingSession={session}
              member={currentMember}
              size="medium"
            />
          )
        },
      },
    ],
    [t, currentMember, user.firstName, user.lastName]
  )

  const handleRowClick = useCallback(
    (dataRow: ChargingSessionWithRelations) => {
      setIsDrawerOpen(true)
      setSelectedChargingSession(dataRow)
    },
    []
  )

  const handleClose = useCallback(() => {
    setIsDrawerOpen(false)
  }, [])

  return (
    <>
      <DataTable<ChargingSessionWithRelations>
        columns={columns}
        onRowClick={handleRowClick}
        emptyTitle={t('employee.chargingSessions.table.empty-title')}
        emptyDescription={t(
          'employee.chargingSessions.table.empty-description'
        )}
        emptyFiltersTitle=""
        emptyFiltersDescription=""
      />
      <Drawer open={isDrawerOpen}>
        <DrawerContent onClose={handleClose}>
          {selectedChargingSession && (
            <ChargingSessionInfoHcp chargingSession={selectedChargingSession} />
          )}
        </DrawerContent>
      </Drawer>
    </>
  )
}
