import { App } from '@capacitor/app'
import { Browser } from '@capacitor/browser'
import { Capacitor } from '@capacitor/core'
import { PushNotifications } from '@capacitor/push-notifications'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSetRecoilState } from 'recoil'

import { EmployeeRoutes } from '../../../routing/routes'
import { inviteIdAtom } from '../../core/recoil/atoms'

import { useAuthentication } from './useAuthentication'

import type { URLOpenListenerEvent } from '@capacitor/app'

/** Use this hook as high as possible inside the AuthProvider to handle redirects on native platforms */
export const useHandleRedirect = () => {
  const { handleRedirectCallback } = useAuthentication()

  const navigate = useNavigate()
  const setInviteId = useSetRecoilState(inviteIdAtom)

  useEffect(() => {
    if (Capacitor.isNativePlatform()) {
      // Handle the 'appUrlOpen' event and call `handleRedirectCallback`
      App.addListener('appUrlOpen', async ({ url }: URLOpenListenerEvent) => {
        // Redirection from the auth0 login page and set auth state

        if (
          url.includes('state') &&
          (url.includes('code') || url.includes('error'))
        ) {
          const { appState } = await handleRedirectCallback(url)

          if (appState?.inviteId) {
            setInviteId(appState.inviteId)
            navigate(EmployeeRoutes.Onboarding)
          } else if (appState.returnTo) {
            navigate(appState.returnTo)
          } else {
            navigate(EmployeeRoutes.Root)
          }

          // Redirection from the auth0 logout page
        } else if (url.includes('blossom.app')) {
          const route = url.split('blossom.app').pop()

          if (route) {
            navigate(route)
          }

          // Deeplinking
        } else {
          const route = url.split('.be').pop() || ''

          if (!['/', '/login', '/login-error'].includes(route)) {
            window.sessionStorage.setItem('initialUrl', route)
          }

          if (route) {
            navigate(route)
          }
        }

        // No-op on Android
        await Browser.close()
      })
    }

    // Listen for notification deeplinks
    if (Capacitor.isNativePlatform()) {
      PushNotifications.addListener(
        'pushNotificationActionPerformed',
        ({ notification }) => {
          const route = notification.data.uri

          if (route) {
            navigate(route)
          }
        }
      )
    }
  }, [])
}
