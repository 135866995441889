import { format } from 'date-fns'
import { Fragment, useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { styled, useTheme } from 'styled-components'

import { BottomSheet } from '../../../components/bottom-sheet/BottomSheet'
import { Chip } from '../../../components/general/Chip'
import {
  BodyExtraSmallRegular,
  BodyExtraSmallSemiBold,
  BodySmallRegular,
} from '../../../components/typography'
import { useGetDateFnsLocale } from '../../../core/hooks/useGetDateFnsLocale'
import { formatDecimals, subtractVat } from '../../../core/utils/number'
import { DataList } from '../../../dataTable/components/DataList'
import { ColumnType } from '../../../dataTable/components/DataTable'
import { valueToDate } from '../../../translations/utils/date'

import { ReimbursementInfo } from './ReimbursementInfo'

import type { Column } from '../../../dataTable/components/DataTable'
import type { Reimbursement } from 'database'

export const ReimbursementsDataList = () => {
  const { t } = useTranslation()
  const dateFnsLocale = useGetDateFnsLocale()
  const theme = useTheme()

  // State
  const [isSheetOpen, setIsSheetOpen] = useState(false)
  const [selectedReimbursement, setSelectedReimbursement] = useState<
    Reimbursement | undefined
  >(undefined)

  const columns = useMemo<Column<Reimbursement>[]>(
    () => [
      {
        key: 'date',
        type: ColumnType.STRING,
        component: BodyExtraSmallSemiBold,
        transformData(reimbursement) {
          return format(
            new Date(reimbursement.date),
            'dd MMMM yyyy',
            dateFnsLocale
          )
        },
      },
      {
        key: 'sessionType',
        type: ColumnType.STRING,
        component: BodyExtraSmallRegular,
        transformData(reimbursement) {
          return t(`employee.reimbursement.${reimbursement.type}`)
        },
      },
      {
        key: 'price',
        type: ColumnType.STRING,
        component: BodySmallRegular,
        transformData(reimbursement) {
          // For third party charging session reimbursements, the price includes VAT,
          // but we should show the amount the user is actually paid back (excluding VAT).
          const IsThirdPartyChargingSession =
            reimbursement.type === 'HostInvoice'

          const correctedPrice = IsThirdPartyChargingSession
            ? subtractVat(reimbursement.price, 21)
            : reimbursement.price

          return <>€ {formatDecimals(correctedPrice)}</>
        },
      },
      {
        key: 'isPaid',
        type: ColumnType.STRING,
        component: Fragment,
        transformData: (reimbursement) => {
          return reimbursement.isPaid ? (
            <Chip
              icon={['fasr', 'check']}
              size="small"
              backgroundColor={theme.theme.colors['tertiary-6']}
            >
              {t('employee.reimbursement.status-paid-out')}
            </Chip>
          ) : (
            <Chip
              icon={['fasr', 'clock']}
              size="small"
              backgroundColor={theme.theme.colors['septenary-4']}
            >
              {t('employee.reimbursement.status-processing')}
            </Chip>
          )
        },
      },
    ],
    [dateFnsLocale, t, theme.theme.colors]
  )

  const reimbursementTitle = useMemo(() => {
    if (!selectedReimbursement) {
      return ''
    }

    const startDate = valueToDate(selectedReimbursement.date)

    return format(startDate, 'dd MMMM yyyy', dateFnsLocale)
  }, [selectedReimbursement, dateFnsLocale])

  const handleRowClick = useCallback((dataRow: Reimbursement) => {
    setSelectedReimbursement(dataRow)
    setIsSheetOpen(true)
  }, [])

  const handleSheetClose = useCallback(() => {
    setIsSheetOpen(false)
  }, [])

  return (
    <StContainer>
      <DataList<Reimbursement>
        columns={columns}
        onRowClick={handleRowClick}
        emptyTitle={t('employee.reimbursement.table.empty-title')}
        emptyFiltersTitle={t(
          'employee.reimbursement.table.empty-filters-title'
        )}
      />
      <BottomSheet
        detent="content-height"
        isOpen={isSheetOpen}
        onClose={handleSheetClose}
        hasBackdrop
        title={reimbursementTitle}
        backgroundColor={theme.theme.colors['nonary-10']}
      >
        {selectedReimbursement && (
          <ReimbursementInfo reimbursement={selectedReimbursement} />
        )}
      </BottomSheet>
    </StContainer>
  )
}

const StContainer = styled.div`
  padding-bottom: calc(var(--inset-bottom) + var(--navigation-height));
`
