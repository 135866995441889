import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { styled } from 'styled-components'

import {
  BodyExtraExtraSmallMediumCss,
  BodyExtraSmallSemiBoldCss,
} from '../typography'

import type { IconProp } from '@fortawesome/fontawesome-svg-core'
import type { PropsWithChildren } from 'react'

export type ChipProps = PropsWithChildren & {
  icon?: IconProp
  iconSrc?: string
  size?: 'small' | 'medium'
  borderRadius?: 'round' | 'square'
  backgroundColor?: string
  color?: string
}

export const Chip = ({
  icon,
  iconSrc,
  children,
  size = 'medium',
  borderRadius = 'round',
  backgroundColor,
  color,
}: ChipProps) => {
  return (
    <StChip
      $size={size}
      $borderRadius={borderRadius}
      $backgroundColor={backgroundColor}
      $color={color}
    >
      {icon ? <StFontAwesomeIcon icon={icon} $size={size} /> : null}
      {iconSrc ? <StCustomIcon alt="icon" src={iconSrc} $size={size} /> : null}
      <StText $size={size}>{children}</StText>
    </StChip>
  )
}

const StChip = styled.div<{
  $size: 'small' | 'medium'
  $borderRadius: 'round' | 'square'
  $backgroundColor?: string
  $color?: string
}>`
  display: flex;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space1};
  align-items: center;
  width: fit-content;
  height: fit-content;

  color: ${({ theme, $color }) => $color ?? theme.theme.colors.black};

  background-color: ${({ theme, $backgroundColor }) =>
    $backgroundColor ?? theme.components.chip.primary['active-bg']};

  padding: ${({ theme, $size }) =>
    $size === 'small'
      ? `${theme.UI.SpacingPx.Space1} ${theme.UI.SpacingPx.Space2}`
      : '6px 12px'};

  border-radius: ${({ theme, $borderRadius }) =>
    $borderRadius === 'round' ? '9999px' : theme.UI.SpacingPx.Space2};
`

const StFontAwesomeIcon = styled(FontAwesomeIcon)<{
  $size: 'small' | 'medium'
}>`
  font-size: ${({ $size }) => ($size === 'small' ? '12px' : '14px')};
`

const StCustomIcon = styled.img<{
  $size: 'small' | 'medium'
}>`
  width: ${({ $size }) => ($size === 'small' ? '12px' : '14px')};
`

const StText = styled.p<{
  $size: 'small' | 'medium'
}>`
  ${({ $size }) =>
    $size === 'small'
      ? BodyExtraExtraSmallMediumCss
      : BodyExtraSmallSemiBoldCss}
`
